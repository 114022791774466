import axios from 'axios';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

const NewPost = () => {
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [imgUrl, setImgUrl] = useState("");
    const [username, setUserName] = useState("");


    const allowedFileTypes = ["image/png", "image/jpeg", "image/gif"];

    let history = useHistory();

    const handleUsername = (e) => {
        e.preventDefault();
        setUserName(e.target.value);
    };

    const handleTitle = (e) => {
        e.preventDefault();
        setTitle(e.target.value);
    };

    const handleText = (e) => {
        e.preventDefault();
        setContent(e.target.value);
    }

    const handleMediaUpload = (e) => {
        e.preventDefault();
        if (allowedFileTypes.indexOf(e.target.files[0].type) > -1) {
            setImgUrl(e.target.files[0]);
            return;
        }
        alert('File type not supported');
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (title === "") {
            alert("Please add a title.");
            return;
        }

        if (imgUrl !== "" && title !== "") {
            let reader = new FileReader();
            reader.readAsDataURL(imgUrl);

            reader.onload = () => {
                let payload = {
                    title,
                    content,
                    username,
                    media: reader.result
                };
                postPayload(payload);
            };
            return;
        }
        if (content !== "" && title !== "") {
            let payload = {
                title,
                content,
                username
            };
            postPayload(payload);
        }
    };

    const postPayload = (payload) => {
        var raw = JSON.stringify(payload);
        axios.post("https://blog-api.rupinjairaj.workers.dev/posts", raw, { withCredentials: true })
            .then(res => history.push('/'))

    }

    return (

        <div className="container" style={{ paddingTop: 20 }}>
            <div className="row">
                <div className="col-6">
                    <form>
                        <div className="form-floating mb-3">
                            <input type="text" className="form-control"
                                id="username" placeholder="enter post title"
                                onChange={handleUsername} />
                            <label htmlFor="username">User name</label>
                        </div>
                        <div className="form-floating mb-3">
                            <input type="text" className="form-control"
                                id="title" placeholder="enter post title"
                                onChange={handleTitle} />
                            <label htmlFor="title">Title</label>
                        </div>
                        <div className="form-floating" style={{ paddingBottom: 10 }}>
                            <textarea className="form-control" placeholder="enter post content"
                                id="content" style={{ height: 100 }}
                                onChange={handleText}></textarea>
                            <label htmlFor="content">Content</label>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="formFile" className="form-label">Image/GIF</label>
                            <input className="form-control" type="file" id="formFile" onChange={handleMediaUpload} />
                        </div>
                        <button type="submit" className="btn btn-primary"
                            onClick={handleSubmit}>Post</button>
                    </form>
                </div>
                {(imgUrl !== "" || title !== "" || content !== "") && <div className="col">
                    <h3>Preview</h3>
                    <div className="card">
                        {imgUrl !== "" && <img src={URL.createObjectURL(imgUrl)} className="card-img-top" alt="image preview" />}
                        <div className="card-body">
                            <h5 className="card-title">{title}</h5>
                            <p className="card-text">{content}</p>
                        </div>
                    </div>
                </div>}
            </div>
        </div >
    );
};

export default NewPost;
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

const Post = () => {
    const [post, setPost] = useState([]);
    const { id } = useParams();

    useEffect(() => {
        const getPost = async () => {
            console.log(`http://localhost:8787/api/posts/${id}`)

            const resp = await fetch(
                `http://localhost:8787/api/posts/${id}`
            );
            const postResp = await resp.json();
            setPost(postResp);
        };

        getPost();
    }, [id]);

    if (!Object.keys(post).length) return <div />
    return (
        <div className="container">
            <div className="row">
                <div className="column"></div>
                <div className="column column-50"><h1>{post.title}</h1></div>
                <div className="column"></div>
            </div>
            <div className="row">
                <div className="column"></div>
                <div className="column column-50">
                    <p>{post.text}</p>
                    <p>
                        <em>Published {new Date(post.published_at).toLocaleString()}</em>
                    </p>
                </div>
                <div className="column"></div>
            </div>
            <div className="row">
                <div className="column"></div>
                <div className="column">
                    <Link to="/">Go Back</Link>
                </div>
                <div className="column"></div>
                <div className="column"></div>
            </div>
        </div>
    );
};

export default Post;
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import Posts from './components/posts';
import Post from './components/post';
import Nav from "./components/nav";
import NewPost from "./components/new-post";

function App() {
  return (
    <Router>
      <div>
        <Nav />

        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route path="/posts/:id">
            <Post />
          </Route>
          <Route path="/new-post">
            <NewPost />
          </Route>
          <Route path="/">
            <Posts />
          </Route>
        </Switch>
      </div>
    </Router >
  );
}

export default App;

import axios from 'axios';
import React, { useEffect, useState } from 'react';

const Posts = () => {
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        const getPosts = async () => {
            axios.get(
                "https://blog-api.rupinjairaj.workers.dev/posts"
            )
                .then(res => setPosts(res.data))
        };

        getPosts();
    }, []);

    return (
        <div className="container">
            <div className="row">
                <div className="col"></div>
                <div className="col"><h1>Posts</h1></div>
                <div className="col"></div>
                <div className="col"></div>
            </div>
            <div className="row">
                <div className="col"></div>
                <div className="col-6">
                    {posts.map((post) => (
                        <div style={{ paddingBottom: 20 }} key={post.id}>
                            <div className="card">
                                <div className="card-body">
                                    {(post.media) && <img src={post.media} className="card-img-top" alt="image preview" />}
                                    <h5 className="card-title">{post.title}</h5>
                                    <p className="card-text">{post.content}</p>
                                </div>
                                <div className="card-footer text-muted">
                                    posted on: {new Date(post.date).toISOString().split('T')[0]}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="col"></div>
            </div>
        </div>
    );
};

export default Posts;

import React from 'react';
import { Link, useHistory } from 'react-router-dom';

const Nav = () => {

    let history = useHistory();

    const handleNewPostClick = (e) => {
        e.preventDefault();
        history.push("/new-post");
    }

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container-fluid">
                <a className="navbar-brand" href="/">CF Blog</a>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <Link className="nav-link" aria-current="page" to="/">
                                Home
                            </Link>
                        </li>
                    </ul>
                    <button className="btn btn-outline-success" onClick={handleNewPostClick}>New Post</button>
                </div>
            </div>
        </nav>
    );
};

export default Nav;
